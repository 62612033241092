<template>
  <div>
    <transition name="fade">
      <CCard v-if="show" color="">
        <CCardHeader>
          Add Academic Year
          <div class="card-header-actions">
            <CLink
              class="card-header-action btn-minimize"
              @click="isCollapsed = !isCollapsed"
            >
              <CIcon :name="`cil-chevron-${isCollapsed ? 'bottom' : 'top'}`" />
            </CLink>
            <CLink
              href="#"
              class="card-header-action btn-close"
              v-on:click="show = false"
            >
              <CIcon name="cil-x" />
            </CLink>
          </div>
        </CCardHeader>
        <CCollapse :show="isCollapsed" :duration="400">
          <CCardBody>
              <div class="form-group row">
                <label
                  for="classFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >Start Date</label
                >
                <div class="col-sm-8">
                 <input type ="date"  name="school" class="form-control"  v-model="form.start_date">
                </div>
              </div>
              <!-- end form-group -->

              <div class="form-group row">
                <label
                  for="subjectFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >End Date</label
                >
                <div class="col-sm-8">
                  <input type ="date" name="school" class="form-control"  v-model="form.end_date">
                </div>
              </div>
              <!-- end form-group -->

              <div class="form-group row">
                <label
                  for="divisionFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >Description</label
                >
                <div class="col-sm-8">
                  <input type ="text" name="school" class="form-control" placeholder="Description"  v-model="form.description">
                </div>
              </div>
              <!-- end form-group -->

            
              <div class="form-group row">
                <div class="col-sm-8 offset-sm-4">
                  <button type="submit" class="btn btn-primary mr-3 text-white" @click="onSubmit()">
                    Submit
                  </button>
                  <button type="submit" class="btn btn-light">
                    Reset
                  </button>
                </div>
              </div>
          </CCardBody>
        </CCollapse>
      </CCard>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import apis from '@/apis';
import $ from "jquery";
import Swal from 'sweetalert2';
const base_url=process.env.VUE_APP_BASE_URL;


export default {
  name: "AddTeacher",
  components: {},
  data: function() {
    return {
    form: {
        userName: "",
        params:'',
        start_date:"",
        end_date:"",
        description:"",
      },
      show: true,
      isCollapsed: true,
      para:[],
      items:[],
      totalRows:""

    };
  },
  methods:{
      get_data() {
        var promise = apis
          .list_AcademicYears()
          .then((response) => {
            this.items = response.data;
            this.totalRows = this.items.length;
          })
          .catch((errror) => {});
      },
      onSubmit(event) {
        if(this.para == '' || this.para == undefined){
            var data = {
                'ac_year_start_date': this.form.start_date,
                'ac_year_end_date': this.form.end_date,
                'ac_year_description': this.form.description,
            };
            console.log(data);
            var promise = apis.add_AcademicYears(data)
            .then( response => {
              Swal.fire({
                    width:300,
                    title: 'Year Added Sucessfully !!',
                    Text: 'Year Added Sucessfully !!'
              });
              this.get_data();
              //this.$router.go(-1);

            }).catch(error => {

            });
          
        }else{
            //  var data = {
            //    'school_id': this.id,
            //     'school_name': this.form.schoolName,
            //     'address': this.form.schoolAddress,
            //     'contact_no': this.form.contact,
            //     'contact_person': this.form.contactPerson,
            //     'district_id': '00000000-0000-0000-0000-000000000000'
            // };
            // console.log(data);
            // axios.put(base_url + 'api/Schools/'+this.id,data).then( response => {
            //   this.isLoading = false;
              
            //   this.$alert("School Updated Sucessfully");
            //   this.$router.go(-1);

            // }).catch(error => {

            // });
        }
           
    },
   
  },
  created(){

  }
};
</script>
